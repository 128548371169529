import React, { useState, useEffect, useMemo } from 'react';
import SideBar from './SideBar';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getDatabase, ref, query, orderByChild, limitToFirst, startAt, onValue, off } from "firebase/database";
import { FixedSizeList as List } from 'react-window';
const DoctorsAppointment = React.lazy(() => import('./DoctorsAppointment'));

function Dashboard() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [diabeticPatientsCount, setDiabeticPatientsCount] = useState(0);

    const Details = useMemo(() => JSON.parse(localStorage.getItem("DoctorDetials")), []);

    const diabetesKeywords = useMemo(() => [
        "diabetes", "glucose", "sugar", "insulin", "prameha",
        "T2DM", "DM", "madhumeha", "meha", "meham",
        "Type 2", "mellitus"
    ], []);

    const getPatientsDataWithPagination = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/users/${Details.doctorName}/profiles`);
            const startAtIndex = (currentPage - 1) * rowsPerPage;
            const patientQuery = query(
                Ref,
                orderByChild('date'),
                startAt(startAtIndex),
                limitToFirst(rowsPerPage)
            );

            const listener = onValue(patientQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const patients = snapshot.val();
                    const patientArray = Object.values(patients);

                    // Calculate diabetic patients
                    const diabeticCount = patientArray.filter((entry) =>
                        diabetesKeywords.some((keyword) =>
                            entry?.casesheet?.toLowerCase().includes(keyword.toLowerCase())
                        )
                    ).length;

                    setData(patientArray);
                    setDiabeticPatientsCount(diabeticCount);
                } else {
                    setData([]);
                    setDiabeticPatientsCount(0);
                }
            });

            // Cleanup listener on unmount
            return () => off(patientQuery, listener);
        } catch (error) {
            console.error("Error in pagination fetch: ", error);
        }
    };

    const getTotalPatientCount = () => {
        const db = getDatabase();
        const Ref = ref(db, `/users/${Details.doctorName}/profiles`);
        const listener = onValue(Ref, (snapshot) => {
            if (snapshot.exists()) {
                setTotalDataCount(Object.keys(snapshot.val()).length);
            } else {
                setTotalDataCount(0);
            }
        });

        return () => off(Ref, listener);
    };

    useEffect(() => {
        const cleanupCount = getTotalPatientCount();
        return () => {
            cleanupCount?.();
        };
    }, [])

    useEffect(() => {
        const cleanupPatients = getPatientsDataWithPagination();

        return () => {
            cleanupPatients?.();
        };
    }, [currentPage, rowsPerPage]);


    // const handleSelect = (date) => {
    //     console.log(date.selection.endDate)
    //     setStartDate(date.selection.startDate)
    //     setEndDate(date.selection.endDate)
    // };



    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    // const getAllPatientsData = () => {
    //     try {
    //         const db = getDatabase();
    //         const Details = JSON.parse(localStorage.getItem("DoctorDetials"));
    //         const Ref = ref(db, `/users/${Details.doctorName}/profiles`); // Reference to all patient profiles under the doctor

    //         onValue(Ref, (snapshot) => {
    //             if (snapshot.exists()) {
    //                 const allPatients = snapshot.val();
    //                 if (allPatients) {
    //                     const dataValues = Object.values(allPatients);

    //                     const sortedDataValues = dataValues.sort((a, b) => {
    //                         return new Date(b.date).getTime() - new Date(a.date).getTime();
    //                     });



    //                     function getCustomDateRange() {
    //                         const startDate = new Date(2025, 0, 1);  // September 4, 2024 (Month is 0-indexed)
    //                         const endDate = new Date(2025, 0, 6);   // October 18, 2024 (Month is 0-indexed)

    //                         return { startDate, endDate };
    //                     }

    //                     const { startDate, endDate } = getCustomDateRange();



    //                     const lastSaturdayTimestamp = startDate.getTime();
    //                     const thisSaturdayTimestamp = endDate.getTime();

    //                     const filteredData = sortedDataValues.filter(entry => {
    //                         const entryTimestamp = Number(entry.firstname.split('_')[1]);
    //                         return entryTimestamp >= lastSaturdayTimestamp && entryTimestamp < thisSaturdayTimestamp;
    //                     }).map(entry => {
    //                         const entryTimestamp = Number(entry.firstname.split('_')[1]);
    //                         const entryDate = new Date(entryTimestamp);
    //                         const formattedDate = entryDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    //                         return { ...entry, date: formattedDate };
    //                     });

    //                     console.log("Filtered Data:", filteredData.map((e)=>e.firstname));

    //                     const dayCounts = {};

    //                     filteredData.forEach(entry => {
    //                         if (!dayCounts[entry.date]) {
    //                             dayCounts[entry.date] = 0;
    //                         }
    //                         dayCounts[entry.date]++;
    //                     })

    //                     console.log("Day Counts:", dayCounts);

    //                     const totalCount = Object.values(dayCounts).reduce((sum, count) => sum + count, 0);
    //                     const averageCount = totalCount / Object.keys(dayCounts).length;

    //                     console.log("Total Count:", totalCount);
    //                     console.log("Average Count:", averageCount);

    //                     // Display the results
    //                     const result = {
    //                         totalCount: filteredData.length,
    //                         dailyCounts: dayCounts,
    //                         averageCount: averageCount.toFixed(2)
    //                     };

    //                     console.log(result);
    //                 }


    //                 let opdCount = 0;
    //                 let ipdCount = 0;

    //                 const sortedDataValues = Object.values(allPatients).sort((a, b) => {
    //                     return new Date(b.date).getTime() - new Date(a.date).getTime();
    //                 });

    //                 sortedDataValues.forEach((entry) => {
    //                     if (entry.patienttype?.toLowerCase() === 'opd') opdCount++;
    //                     if (entry.patienttype?.toLowerCase() === 'ipd') ipdCount++;
    //                 });

    //                 // // Set counts and data
    //                 // setOPD(opdCount);
    //                 // setIPD(ipdCount);
    //                 // setTotalDataCount(sortedDataValues.length);


    //                 const indexOfLastItem = currentPage * rowsPerPage;
    //                 const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    //                 const currentItems = sortedDataValues.slice(indexOfFirstItem, indexOfLastItem);
    //                 setData(currentItems);
    //             } else {
    //                 console.log("No patients available.");
    //                 // setData([]);
    //                 // setOPD(0);
    //                 // setIPD(0);
    //                 // setTotalDataCount(0);
    //             }
    //         }, (error) => {
    //             console.error("Error fetching data: ", error);
    //         });

    //     } catch (error) {
    //         console.log("Error getting all patients' casesheet:", error);
    //     }
    // };

    
    // getAllPatientsData()

    const opdCount = useMemo(() => data.filter(entry => entry.patienttype?.toLowerCase() === 'opd').length, [data]);
    
    const ipdCount = useMemo(() => data.filter(entry => entry.patienttype?.toLowerCase() === 'ipd').length, [data]);

    return (
        <>
            <SideBar />
            <div className="p-4 sm:ml-64">
                <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                    <div className='Main-contents'>
                        <div className='Cards'>
                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                    <PendingActionsIcon style={{ color: "green" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{totalDataCount}</p>
                                    <p>Total Patients</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                    <CalendarMonthIcon style={{ color: "green" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{opdCount}</p>
                                    <p>OPD Patients</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                    <EventAvailableIcon style={{ color: "blue" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{ipdCount}</p>
                                    <p>IPD Patients</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#FFCCCB" }}>
                                    <PendingActionsIcon style={{ color: "red" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{diabeticPatientsCount}</p>
                                    <p>Diabetic Patients</p>
                                </div>
                            </div>
                        </div>

                       
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <DoctorsAppointment
                                data={data}
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                totalDataCount={totalDataCount}
                                ListComponent={List}
                            />
                        </React.Suspense>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
