import React, { useEffect } from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getDatabase, ref, onValue, set, get, remove } from "firebase/database";
import DeleteIcon from '@mui/icons-material/Delete';
import TableFooter from '@mui/material/TableFooter'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { saveAs } from 'file-saver';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import axios from 'axios';
function DoctorsAppointment({ data, totalDataCount, currentPage, setPage, rowsPerPage, setRowsPerPage }) {

    const [data2, setData2] = useState([]);
    const [Search, setsearch] = useState('')
    const [about, setAbout] = useState('')

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    // let getData = () => {
    //     try {
    //         const db = getDatabase();
    //         const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
    //         const Ref = ref(db, `/users/${Details.doctorName}/profiles`);
    //         onValue(Ref, async (snapshot) => {
    //             try {
    //                 const data2 = await snapshot.val()
    //                 setData(Object.values(data2))
    //                 console.log(data2)
    //             }
    //             catch (error) {
    //                 console.log(error)
    //             }

    //         })

    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }
    // useEffect(() => {
    //     getData()
    // }, [])

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }
    let Navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));

    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatDate = (originalDateString) => {
        const date = new Date(originalDateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const [patientData, setPatientData] = useState({
        OPNo: '',
        FirstName: '',
        LastName: '',
        Occupation: '',
        Address: '',
        DateOfConsultation: '19-06-2024',
        PatientType: '',
        Gender: '',
        Age: '',
        Pincode: '',
        PhoneNo: '',
        Email: 'NA',
        StudyID: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (name === 'DateOfConsultation') {
            newValue = formatDate(value);
        }
        setPatientData(prevData => ({
            ...prevData,
            [name]: newValue
        }));
    };

    const SavePatientData = async () => {
        try {
            const requiredFields = ['PhoneNo', 'Pincode'];
            const hasErrors = requiredFields.some(field => !patientData[field] || patientData[field].trim() === '');
            if (hasErrors) {
                alert('Please fill out all the required fields.');
                return;
            }

            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))

            function generateUniqueId() {
                const prefix = "AYURAI_AY_T_";
                const opno = patientData?.OPNo || Date.now().toString();
                const timestamp = Date.now().toString();
                return `${prefix}${opno}_${timestamp}`;
            }

            const { FirstName } = patientData
            const firstname = `${FirstName}_${Date.now().toString()}`
            const Payload = {
                VIKRITIPULSEHISTORY: "",
                VIKRITIVATAPULSE: "",
                address: patientData?.Address || "",
                age: patientData?.Age || "",
                date: new Date().toDateString(),
                doctor: Details?.doctorName,
                email: patientData?.Email,
                firstname: firstname,
                gender: patientData?.Gender,
                id: generateUniqueId(),
                kappa: "",
                lastname: patientData?.LastName,
                occupation: patientData?.Occupation,
                opno: patientData?.OPNo,
                patienttype: patientData?.PatientType,
                phone: patientData?.PhoneNo || "",
                pincode: patientData?.Pincode || "",
                pitta: "",
                screendate: patientData?.DateOfConsultation,
                vata: ""
            }
            const Ref = ref(db, `/users/${Details.doctorName}/profiles/${firstname}`);
            await set(Ref, Payload)
            handleClose()
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteProfile = (key) => {
        try {
            const db = getDatabase();
            const Details = JSON.parse(localStorage.getItem("DoctorDetials"))
            const Ref = ref(db, `/users/${Details.doctorName}/profiles/${key}`);
            remove(Ref)
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchData = async () => {
        const db = getDatabase();
        const Details = JSON.parse(localStorage.getItem("DoctorDetials"));
        const Ref = ref(db, `/users/${Details.doctorName}/`);

        onValue(Ref, async (snapshot) => {
            const row = [];
            snapshot.forEach((childSnapshot) => {
                const subrow = [];
                const key = childSnapshot.key;

                const firstnameRef = ref(db, `/users/${Details.doctorName}/profiles/${key}/`);
                onValue(firstnameRef, (firstnameSnapshot) => {
                    const firstname = firstnameSnapshot.val();
                    if (firstname) {
                        subrow.push(firstname);
                    }
                });


                const prakrutiRef = ref(db, `/users/${Details.doctorName}/${key}/prakruti/`);
                onValue(prakrutiRef, (prakrutiSnapshot) => {
                    subrow.push(prakrutiSnapshot.exists() ? 'Y' : 'N');
                });


                const casesheetRef = ref(db, `/users/${Details.doctorName}/${key}/casesheet/`);
                onValue(casesheetRef, (casesheetSnapshot) => {
                    subrow.push(casesheetSnapshot.exists() ? 'Y' : 'N');
                });


                const vikritiRef = ref(db, `/users/${Details.doctorName}/${key}/vikriti/`);
                onValue(vikritiRef, (vikritiSnapshot) => {
                    subrow.push(vikritiSnapshot.exists() ? 'Y' : 'N');
                });


                const pulsedataRef = ref(db, `/users/${Details.doctorName}/${key}/pulsedata/`);
                onValue(pulsedataRef, (pulsedataSnapshot) => {
                    subrow.push(pulsedataSnapshot.exists() ? 'Y' : 'N');
                });

                row.push(subrow);
            });

            setData2(row);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);




    const header = ["Profile ID", "address",
        "age",
        "date",
        "doctor",
        "email",
        "firstname",
        "gender",
        "id",
        "kappa",
        "lastname",
        "occupation",
        "opno",
        "patienttype",
        "phone",
        "pincode",
        "pitta",
        "screendate",
        "vata",
        "AGNI", "ALLERGY",
        "ANAEMIA",
        "APD",
        "ASSOCIATED",
        "ASTHMA_CASE",
        "AYURVEDADIAGNOSIS",
        "BPCASE",
        "CHILDREN",
        "CLINICALEXAMINATION",
        "DECREASED",
        "DOMINANT",
        "DRUGS",
        "EAOA",
        "EYESIGHT",
        "FBS",
        "HABITS_ALCOHOL",
        "HABITS_SMOKING",
        "HABITS_TOBACCO",
        "HBAC",
        "HEADACHE",
        "HEIGHTCASE",
        "HISTORYOFDISEASES",
        "HYPERTENSION_CASE",
        "HYSTERECTOMY",
        "IHD",
        "INCREASED",
        "INJURIES",
        "JAUNDICE",
        "KOSHTAM",
        "MALAM",
        "MANAS",
        "MENSTURATION",
        "MODERNDIAGNOSIS",
        "MUTRAM",
        "NIDRA",
        "OTHERDISEASE",
        "PAST_HISTORY",
        "PILES",
        "PPBS",
        "PRESENTING_COMPLAINTS",
        "PULSERATECASE",
        "SEIZURES",
        "SKINDISEASE",
        "SURGERIES",
        "TB",
        "TEMPERATURECASE",
        "THYROID",
        "URINARYCALCULI",
        "VERTIGO",
        "VYADHIAVASTHA",
        "VYAYAMAM",
        "WEIGHTCASE",
        "audiopath",
        "doctorname",
        "patientid",
        "pulserate",
        "timestamp"]

    const header3 = ["ID", "prakruti", "casesheet", "vikriti", "pulsedata"];

    // async function caseSheet() {
    //     const db=getDatabase()
    //     const casesheet = ref(db, "/users/drlm/");
    //     const ref2 = await get(casesheet);
    //     const profiles = ref2.val();

    //     let csvData = [header];

    //     for (const key in profiles) {
    //         const profile = profiles[key];
    //         let row = [key];

    //         for (const field of header) {
    //             row.push(profile[field] || 'na');
    //         }
    //         const casesheetRef = ref(db, `/users/drlm/${key}/casesheet/`);
    //         const casesheetData = await get(casesheetRef);
    //         const casesheet = casesheetData.val();
    //         if (casesheet) {
    //             for (const field in casesheet) {
    //                 row.push(casesheet[field] || 'na');
    //             }
    //         }

    //         csvData.push(row);
    //     }

    //     const csvString = csvData.map(row => row.join(',')).join('\n');
    //     console.log('abc')
    //     const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    //     saveAs(blob, 'drbala_casesheet.csv');
    // }

    async function caseSheet() {
        const db = getDatabase()
        const ref1 = ref(db, '/users/drlm/');
        let ref2Data = [];
        onValue(ref1, (vikritiSnapshot) => {
            ref2Data = vikritiSnapshot.val();
        });


        const profileref = ref(db, '/users/drlm/profiles/');
        let profilerefData = []
        onValue(profileref, (Snapshot) => {
            profilerefData = Snapshot.val();
        });


        const rows = [];

        for (const key in profilerefData) {
            const subrow = [key];
            const ref3 = await ref(db, `/users/drlm/profiles/${key}/`)
            let profileData = []
            onValue(ref3, (Snapshot) => {
                profileData = Snapshot.val();
            });

            if (profileData) {
                for (const key1 in profileData) {
                    console.log(key1, typeof profileData[key1]);
                    subrow.push(profileData[key1]);
                }
            }

            const ref4 = await ref(db, `/users/drlm/${key}/casesheet/`)
            let caseSheetData = [];
            onValue(ref4, (Snapshot) => {
                caseSheetData = Snapshot.val();
            });


            if (caseSheetData) {
                for (const key2 in caseSheetData) {
                    let val = "";
                    if (typeof caseSheetData[key2] === 'string') {
                        if (caseSheetData[key2].length === 0) {
                            val = "na";
                        } else {
                            val = caseSheetData[key2].replace("\n", "&");
                        }
                    } else {
                        val = caseSheetData[key2];
                    }

                    console.log(key2);
                    subrow.push(val);
                }
            }

            rows.push(subrow);
        }
        console.log(rows)
    }


    const downloadData = async () => {


        console.log('abc')

        // async function caseSheet2() {
        //     const ref = db.ref("/users/drlm/");
        //     const ref2 = await ref.once('value');
        //     const profiles = ref2.val();

        //     let csvData = [header3];

        //     for (const key in profiles) {
        //         const profile = profiles[key];
        //         let row = [key];

        //         for (const field of header3) {
        //             row.push(profile[field] || 'na');
        //         }

        //         csvData.push(row);

        //         const csvString = csvData.map(row => row.join(',')).join('\n');
        //         const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
        //         saveAs(blob, 'drbala_casesheet2.csv');
        //     }

        // }
        // async function caseSheet3() {
        //     const ref = db.ref("/users/drlm/");
        //     const ref2 = await ref.once('value');
        //     const profiles = ref2.val();

        //     let csvData = [header];
        //     for (const key in profiles) {
        //         const profile = profiles[key];
        //         let row = [key];


        //         for (const field of header) {
        //             row.push(profile[field] || 'na');
        //         }


        //         const casesheetRef = db.ref(`/users/drlm/${key}/casesheet/`);
        //         const casesheetData = await casesheetRef.once('value');
        //         const casesheet = casesheetData.val();
        //         if (casesheet) {
        //             for (const field in casesheet) {
        //                 row.push(casesheet[field] || 'na');
        //             }
        //         }

        //         csvData.push(row);
        //     }


        //     const csvString = csvData.map(row => row.join(',')).join('\n');

        //     const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
        //     saveAs(blob, 'drbala_casesheet3.csv');
        // }
        // async function prakriti() {
        //     const ref = db.ref("/users/drlm/");
        //     const ref2 = await ref.once('value');
        //     const profiles = ref2.val();

        //     let csvData = [header];

        //     for (const key in profiles) {
        //         const profile = profiles[key];
        //         let row = [key];


        //         for (const field of header) {
        //             row.push(profile[field] || 'na');
        //         }

        //         csvData.push(row);
        //     }


        //     const csvString = csvData.map(row => row.join(',')).join('\n');
        //     const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
        //     saveAs(blob, 'drbala_prakriti.csv');
        // }
        // async function getData() {
        //     const ref = db.ref("/users/drlm/");
        //     const ref2 = await ref.once('value');
        //     const profiles = ref2.val();

        //     let csvData = [header3];

        //     for (const key in profiles) {
        //         const profile = profiles[key];
        //         let row = [key];


        //         for (const field of header3) {
        //             row.push(profile[field] || 'na');
        //         }

        //         csvData.push(row);
        //     }


        //     const csvString = csvData.map(row => row.join(',')).join('\n');


        //     const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
        //     saveAs(blob, 'drbala_casesheetstatus.csv');
        // }


        await caseSheet();
        // await caseSheet2();
        // await caseSheet3();
        // await prakriti();
        // await getData();


    };

    const isValidPhoneNumber = (phoneNo) => {
        return /^\d{10}$/.test(phoneNo);
    };

    const isValidPincode = (pincode) => {
        return /^\d{6}$/.test(pincode);
    };

    function groupByScreendate(items) {
        let groupedData = [];

        items.forEach(item => {
            let existingGroup = groupedData.find(group => group.screendate === item.screendate);
            if (!existingGroup) {
                existingGroup = {
                    screendate: item.screendate,
                    opno: [],
                    missed_sequence: []
                };
                groupedData.push(existingGroup);
            }
            existingGroup.opno.push(item.opno);
        });

        // Sort opno array and find missing numbers
        groupedData.forEach(group => {
            group.opno.sort((a, b) => a - b);
            let minOpno = Number(group.opno[0]);
            let maxOpno = Number(group.opno[group.opno.length - 1]);
            for (let i = minOpno; i < maxOpno; i++) {
                if (!group.opno.includes(i)) {
                    group.missed_sequence.push(i);
                }
            }
        });

        return groupedData;
    }



    return <>

        <h1 className='Order_title'>Patients</h1>
        <div className='Search-bar'>
            <div style={{ margin: "1rem" }}>
                <Button onClick={handleClickOpen} variant='outlined'>Add Patients</Button>
            </div>

            <div className='search-box'>
                <SearchOutlined />
                <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

            </div>
        </div>
        {/* <button onClick={downloadData}>Download</button> */}
        <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
            <Table stickyHeader aria-label="sticky table" >
                <TableHead>
                    <TableRow>

                        <TableCell className='Table-header'
                            style={{ minWidth: "100px" }}

                        >
                            Patient Name
                        </TableCell>
                        <TableCell className='Table-header'
                            align={"center"}
                            style={{ minWidth: "100px" }}

                        >
                            ID
                        </TableCell>
                        <TableCell className='Table-header'
                            align={"center"}
                            style={{ minWidth: "100px" }}

                        >
                            OP NO
                        </TableCell>
                        <TableCell className='Table-header'
                            align={"center"}
                            style={{ minWidth: "100px" }}

                        >
                            Age
                        </TableCell>
                        <TableCell className='Table-header'
                            align={"center"}
                            style={{ minWidth: "100px" }}

                        >
                            Date of Screening
                        </TableCell>
                        <TableCell className='Table-header'
                            align={"center"}
                            style={{ minWidth: "100px" }}

                        >
                            More info
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.filter((x) => {
                        if (Search === '') {
                            return x
                        }
                        else if (x?.firstname.toLowerCase()?.includes(Search?.toLowerCase()) || x?.opno?.includes(Search) || x?.age?.includes(Search)) {
                            return x
                        }
                    }).map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                <TableCell >
                                    {`${row?.firstname?.split('_')[0]} ${row?.lastname}`}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {row?.id}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {row?.opno}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {row?.age}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {row?.screendate}
                                </TableCell>

                                <TableCell align={"center"}>
                                    <div>
                                        <Button variant="outlined" onClick={() => Navigate(`/Patient/${row?.firstname}`)}>
                                            view
                                        </Button>
                                        <IconButton>
                                            <DeleteIcon onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this profile?")) {
                                                    deleteProfile(row?.firstname);
                                                }
                                            }}></DeleteIcon>
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TableFooter align="right">
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500]}
                    component="div"
                    count={totalDataCount}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableFooter>
        </TableContainer>

        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Add Patient</DialogTitle>
            <DialogContent>
                <div className='patientinputtypes'>
                    <TextField label="Study ID" variant='standard' id="standard-size-small" name="StudyID" value={patientData.StudyID} onChange={handleChange} />
                    <TextField label="OP No" variant='standard' id="standard-size-small" name="OPNo" value={patientData.OPNo} onChange={handleChange} />
                    <TextField label="First Name" variant='standard' id="standard-size-small" name="FirstName" value={patientData.FirstName} onChange={handleChange} />
                    <TextField label="Last Name" variant='standard' id="standard-size-small" name="LastName" value={patientData.LastName} onChange={handleChange} />
                    <TextField label="Occupation" variant='standard' id="standard-size-small" name="Occupation" value={patientData.Occupation} onChange={handleChange} />
                    <TextField label="Address" variant='standard' id="standard-size-small" name="Address" value={patientData.Address} onChange={handleChange} />
                    <label style={{ padding: "5px" }}>Date of Consultation
                        <input
                            type='date'
                            name="DateOfConsultation"
                            // value={patientData.DateOfConsultation || ''}
                            onChange={handleChange}
                        />
                    </label>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Patient Type</FormLabel>
                        <RadioGroup aria-label="patient-type" name="PatientType" row value={patientData.PatientType} onChange={handleChange}>
                            <FormControlLabel value="OPD" control={<Radio />} label="OPD" />
                            <FormControlLabel value="IPD" control={<Radio />} label="IPD" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup aria-label="gender" name="Gender" row value={patientData.Gender} onChange={handleChange}>
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                        </RadioGroup>
                    </FormControl>
                    <TextField label="Age" variant='standard' id="standard-size-small" name="Age" value={patientData.Age} onChange={handleChange} />
                    <TextField
                        label="Pincode"
                        variant='standard'
                        id="standard-size-small"
                        name="Pincode"
                        value={patientData.Pincode}
                        onChange={handleChange}
                        error={!isValidPincode(patientData.Pincode)}
                        helperText={!isValidPincode(patientData.Pincode) ? 'Please enter a valid 6-digit PIN code' : ''}
                    />
                    <TextField
                        label="Phone No"
                        variant='standard'
                        id="standard-size-small"
                        name="PhoneNo"
                        value={patientData.PhoneNo}
                        onChange={handleChange}
                        error={!isValidPhoneNumber(patientData.PhoneNo)}
                        helperText={!isValidPhoneNumber(patientData.PhoneNo) ? 'Please enter a 10-digit phone number' : ''}
                    />
                    <TextField label="Email (Optional)" variant='standard' id="standard-size-small" name="Email" value={patientData.Email} onChange={handleChange} />
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" onClick={
                    SavePatientData}>Save</Button>
            </DialogActions>
        </Dialog>

    </>
}


export default DoctorsAppointment